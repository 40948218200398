<template>
  <v-container grid-list-xl text-md-center>

    <!-- Title -->
    <v-layout row justify-center>
      <v-flex md12>
        <v-card-title primary-title class="justify-center">
          <h3 class="text--accent-2">{{ categoryDisplayName }}</h3>
        </v-card-title>
      </v-flex>
    </v-layout>

    <!-- Actual Gallery Loop -->
    <v-layout row justify-center pa5>
      <v-flex xs12 md2 v-for="gallery in this.galleries" :key="gallery.name">
        <v-hover v-slot:default="{ hover }">
          <v-card
            justify-center
            outlined
            :class="{ 'on-hover': hover }"
            :to="getGalleryRoute(gallery)"
          >
            <v-img :src="getCoverImage(gallery)" aspect-ratio="1"></v-img>
            <v-card-subtitle justify-space-between class="accent">{{ gallery.display_name }}</v-card-subtitle>
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'

  export default {
    name: 'Galleries',
    props: ['categoryName'],
    watch: {
      categoryName: function(newVal, oldVal) {
        console.log('Category changed from: ', oldVal, 'to ', newVal)
        this.getGalleries()
      }
    },

    mounted () {
      this.getGalleries()
    },

    data () {
      return {
        baseurl: process.env.VUE_APP_API,
        categoryDisplayName: '',
        galleries: {},
      }
    },

    computed: {
      apiGalleriesPath: function() {
        return '/api/galleries/' + this.categoryName
      },
      apiAssetsPath: function() {
        return '/assets'
      },
      galleryApiUrl: function() {
        return this.baseurl + this.apiGalleriesPath
      },
      assetApiUrl: function() {
        return this.baseurl + this.apiAssetsPath
      },
    },

    methods: {

      getGalleries: function() {
        console.log("Fetching galleries from " + this.galleryApiUrl)

        axios({
          method: 'GET',
          'url': this.galleryApiUrl,
        }).then(result => {
          let data = result.data
          this.galleries = data.galleries
          this.categoryDisplayName = data.category_display_name
        })
      },

      getCoverImage: function(gallery) {
        /**
         * Returns the URL on which the Cover Picture can be fetched
         **/
        var url = this.assetApiUrl + gallery.cover_picture_thumbnail
        return url
      },

      getGalleryRoute: function(gallery) {
        /**
         * Returns the internal app route for a given gallery
         *
         * We are generating the internal UI Links with this method
         **/
        var url = '/showgallery/' + this.categoryName + '/' + gallery.name
        return url
      }
    },

  }
</script>

<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}
</style>
