<template>
  <galleries
    :category-name="$route.params.catName" />
</template>

<script>
import Galleries from '@/components/Galleries.vue'

export default {
  name: 'ShowGalleries',
  props: ['catName', 'catDisplayName'],
  components: {
    Galleries
  }
}
</script>
